import { h, render } from 'preact'

const embedModules = {
    StoryLink: 'StoryLink/StoryLink',
    Rank: 'Rank/Rank',
    Score: 'Score/Score',
    Grade: 'Grade/Grade',
    Trade: 'Trade/Trade',
    Matchup: 'Matchup/Matchup',
    GroupOfLinks: 'GroupOfLinks/GroupOfLinks',
}

const renderEmbeds = async () => {
    const embeds: NodeListOf<HTMLElement> = window.document.querySelectorAll('.mm-content-embed')

    for (const embed of embeds) {
        const embedType: string | undefined = embed.dataset.type
        delete embed.dataset.type

        const data: DOMStringMap = embed.dataset

        const props: Object = {}
        for (const name in data) {
            props[name] = data[name]
        }

        const parent = embed.parentNode
        const container = document.createElement('div')

        parent?.insertBefore(container, embed)

        if (embedType) {
            try {
                const { default: Component } = await import(`./embeds/${embedModules[embedType]}`)
                render(<Component {...props} />, container)
            } catch (e) {
                console.error(e)
            }
        }

        parent?.removeChild(embed)
    }
}

if (document.readyState != 'loading') {
    renderEmbeds()
} else {
    document.addEventListener('DOMContentLoaded', () => renderEmbeds())
}

const previewEmbed = async (embedType: string, data: any, anchor: HTMLElement, preview: boolean) => {
    try {
        const { default: Component } = await import(`./embeds/${embedModules[embedType]}`)
        render(<Component {...data} preview={preview} />, anchor)
    } catch (e) {
        console.error(e)
    }
}

window.__mm_content_enrichment__ = { renderEmbeds, previewEmbed }
